/* eslint-disable max-len */
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Content } from '../model/content';
import { Gallery } from '../model/gallery';
import { AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class ContentService {
  pageArray = [];
  pageRef: AngularFireObject<any>;

  constructor(private db: AngularFirestore, private alertController: AlertController, public ngFireAuth: AngularFireAuth,) {

  }

  loadLiveList() {
    return this.db.collection('content', (ref) => ref.orderBy('order'));
  }

  getAllParents() {
    return this.db.collection('content', (ref) =>
    ref.where('parent', '==', 'none')
    .orderBy('order'));
  }

  togglePublished(id: string, onOff: string) {
    return this.db.collection('content').doc(id).update({published: onOff});
  }

  getPage(id: string) {
    return this.db.collection('content').doc(id).snapshotChanges();
  }

  viewPageByURL(url: string) {
    return this.db.collection('content', ref => ref.where('slug', '==', url)).valueChanges();
  }

  getBlock(codeword: string) {
    return this.db.collection('blocks', ref => ref.where('codeword', '==', codeword)).valueChanges();
  }

  getGalleryByPage(url: string) {
    return this.db.collection('content', ref => ref.where('slug', '==', url)).snapshotChanges();
  }

  getTemplate(id: string) {
    return this.db.collection('templates').doc(id).valueChanges();
  }

  createPage(content: Content) {
    return this.db.collection('content').add({
      title: content.title,
      homepage: content.homepage,
      template: content.template,
      forwardTick: content.forwardTick,
      forward: content.forward,
      body: content.body,
      parent: content.parent,
      url: content.url,
      published: content.published,
      timestamp: content.timestamp,
      metaTitle: content.metaTitle,
      metaDescription: content.metaDescription,
      order: content.order,
      slug: content.slug,
    });
  }

  duplicatePage(id) {
    const pageContent = this.getPage(id).subscribe(res => {
      const a = JSON.parse(JSON.stringify(res.payload.data()));
      console.log(a);
      this.db.collection('content').add({
        title: a.title,
        homepage: a.homepage,
        template: a.template,
        forwardTick: a.forwardTick,
        forward: a.forward,
        body: a.body,
        parent: a.parent,
        url: a.url,
        published: false,
        timestamp: a.timestamp,
        metaTitle: a.metaTitle,
        metaDescription: a.metaDescription,
        order: Number(a.order)+10,
        slug: '',
      });
      pageContent.unsubscribe();
    });
  }

  updatePage(id: string, content: Partial<Content>) {
    return from(this.db.doc(`content/${id}`).update(content));
  }

  updateMultiple(collection, id, data) {
    return this.db.collection(collection).doc(id).update(data);
  }

  async deletePage(id){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Delete This Page',
      message: 'Are You Sure?',
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Okay',
          handler: () => {
            this.db.collection('content').doc(id).delete();
          }
        }
      ]
    });

    return await alert.present();
  }

  createGalleryItem(gallery: Gallery, id: string){
    return this.db.collection(`/content/${id}/gallery`).add({
        created: gallery.created,
        filename: gallery.filename,
        order: gallery.order,
        content: gallery.content,
        page: gallery.page,
    });
  }

  getGallery(id: string) {
    return this.db.collection(`/content/${id}/gallery`, (ref) => ref.orderBy('order'));
  }

  getScrollingGallery(id: string) {
    return this.db.collection(`/content/${id}/gallery`, (ref) => ref.orderBy('order')).snapshotChanges();
  }

  getSingleGallery(id: string, galleryId: string) {
    return this.db.collection(`/content/${id}/gallery`).doc(galleryId).snapshotChanges();
  }

  updateSingleGallery(id: string, galleryId: string, content: Partial<Content>) {
    return from(this.db.doc(`content/${id}/gallery/${galleryId}`).update(content));
  }

  async deleteGalleryItem(id, galleryid){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Delete This Page',
      message: 'Are You Sure?',
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Okay',
          handler: () => {
            this.db.collection(`content/${id}/gallery`).doc(galleryid).delete();
          }
        }
      ]
    });

    return await alert.present();
  }

}
