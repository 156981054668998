/* eslint-disable max-len */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-header-gallery',
  templateUrl: './header-gallery.component.html',
  styleUrls: ['./header-gallery.component.scss'],
})
export class HeaderGalleryComponent implements OnInit {

  sliderOne: any;
  pageUrl: any;
  pageId: string;
  pageArray = [{}];
  sminky: any;
  pageArray2 = [];

  slideOptsOne = {
    initialSlide: 0,
    speed: 800,
    autoplay: true,
    loop: true
  };

  constructor(
    private actRoute: ActivatedRoute,
    private contentService: ContentService,
    private db: AngularFirestore,
  ) {
    this.sliderOne =
    {
      isBeginningSlide: true,
      isEndSlide: false,
    };
  }

  ngOnInit() {
    this.pageUrl = this.actRoute.snapshot.paramMap.get('pageUrl');
    this.getPage();

  }


  getPage() {
    const array = this.contentService.getGalleryByPage(this.pageUrl).subscribe(res => {
      res.forEach((doc) => {
        const y = doc.payload.doc.id;
        // console.log(y);
        this.pageId = y;
        const list = this.contentService.getGallery(y);
        list.valueChanges({ idField: 'id' }).subscribe((ss) => {
          this.pageArray2 = ss;
          // console.log(this.pageArray2);
          // console.log('legnth' + this.pageArray2.length);
          if (this.pageArray2.length === 0) {
            // console.log('empty array');
            this.getFrontPage();
          }
        });
      });
    });
  }

  getFrontPage() {
    const array = this.contentService.getGalleryByPage('home').subscribe(res => {
      res.forEach((doc) => {
        const y = doc.payload.doc.id;
        // console.log(y);
        this.pageId = y;
        const list = this.contentService.getGallery(y);
        list.valueChanges({ idField: 'id' }).subscribe((ss) => {
          this.pageArray2 = ss;
          // console.log('legnth' + this.pageArray2.length);
          if (this.pageArray2.length === 0) {
            // console.log('empty array');
          }
        });
      });
    });
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  slideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disbale navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }


}
