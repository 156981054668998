import { GuardWireCoversComponent } from './guard-wire-covers/guard-wire-covers.component';

/* eslint-disable max-len */
import { TestimonialComponent } from './testimonial/testimonial.component';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  NgModule,
  ComponentFactoryResolver,
} from '@angular/core';

import { HeaderComponent } from 'src/app/components/header/header.component';
import { HeaderGalleryComponent } from 'src/app/components/header-gallery/header-gallery.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { SliderComponent } from 'src/app/components/slider/slider.component';
import { MenuComponent } from './menu/menu.component';

import { BoomCoversComponent } from './boom-covers/boom-covers.component';
import { SnuffersComponent } from './snuffers/snuffers.component';
import { StackPacksComponent } from './stack-packs/stack-packs.component';
import { FurlCoversComponent } from './furl-covers/furl-covers.component';

import { QuoteComponent } from './quote/quote.component';

import { ContentComponent } from 'src/app/components/content/content.component';

import { TabsPage } from './../tabs/tabs.page';

import { BaseModule } from '../common/base-module';

import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';

const componentParsers: Array<HookParserEntry> = [
  { component: HeaderComponent },
  { component: HeaderGalleryComponent },
  { component: FooterComponent },
  { component: SliderComponent },
  { component: MenuComponent },
  { component: BoomCoversComponent },
  { component: SnuffersComponent },
  { component: StackPacksComponent },
  { component: FurlCoversComponent },
  { component: QuoteComponent },
  { component: GuardWireCoversComponent },
  { component: TabsPage },
];

// Remember: If you're using a shared module for all the includes, then you need to import the RouterModule here to make the routerLinks work in the header
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers,
    }),
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  declarations: [
    HeaderComponent,
    HeaderGalleryComponent,
    SliderComponent,
    TestimonialComponent,
    FooterComponent,
    MenuComponent,
    BoomCoversComponent,
    SnuffersComponent,
    StackPacksComponent,
    FurlCoversComponent,
    QuoteComponent,
    GuardWireCoversComponent,
    TabsPage,
    ContentComponent,
  ],
  exports: [
    HeaderComponent,
    HeaderGalleryComponent,
    SliderComponent,
    TestimonialComponent,
    FooterComponent,
    MenuComponent,
    TabsPage,
    ContentComponent,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule extends BaseModule {
  dynamicComponents = [
    HeaderComponent,
    HeaderGalleryComponent,
    SliderComponent,
    TestimonialComponent,
    FooterComponent,
    MenuComponent,
    BoomCoversComponent,
    SnuffersComponent,
    TabsPage,
    ContentComponent,
  ];

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
}
