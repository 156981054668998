import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ContentService } from './services/content.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  pageArray = [];
  location = window.location.hostname;
  plop = 'plop';

  constructor(public menu: MenuController, private contentService: ContentService, private fireStore: AngularFirestore) {}

  ngOnInit() {
    this.onListPages();
  }

  toggleMenu() {
    this.menu.toggle(); //Add this method to your button click function
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  onListPages() {
    const list = this.contentService.loadLiveList();
    list.valueChanges({idField: 'id'}).subscribe((ss) => {
      this.pageArray = ss;
      // console.log('pageArray:');
      // console.log(this.pageArray);
    });
  }
}
