import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // admin
  {
    path: 'admin',
    loadChildren: () => import('./admin/pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'admin/pages',
    loadChildren: () => import('./admin/pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'admin/pages-make',
    loadChildren: () => import('./admin/pages-make/pages-make.module').then( m => m.PagesMakePageModule)
  },
  {
    path: 'admin/pages-edit/:id',
    loadChildren: () => import('./admin/pages-edit/pages-edit.module').then( m => m.PagesEditPageModule)
  },
  {
    path: 'admin/templates',
    loadChildren: () => import('./admin/templates/templates.module').then( m => m.TemplatesPageModule)
  },
  {
    path: 'admin/templates-make',
    loadChildren: () => import('./admin/templates-make/templates-make.module').then( m => m.TemplatesMakePageModule)
  },
  {
    path: 'admin/templates-edit/:id',
    loadChildren: () => import('./admin/templates-edit/templates-edit.module').then( m => m.TemplatesEditPageModule)
  },
  {
    path: 'admin/blocks',
    loadChildren: () => import('./admin/blocks/blocks.module').then( m => m.BlocksPageModule)
  },
  {
    path: 'admin/blocks-make',
    loadChildren: () => import('./admin/blocks-make/blocks-make.module').then( m => m.BlocksMakePageModule)
  },
  {
    path: 'admin/blocks-edit/:id',
    loadChildren: () => import('./admin/blocks-edit/blocks-edit.module').then( m => m.BlocksEditPageModule)
  },
  {
    path: 'admin/galleries',
    loadChildren: () => import('./admin/galleries/galleries.module').then( m => m.GalleriesPageModule)
  },
  {
    path: 'admin/galleries-edit/:id',
    loadChildren: () => import('./admin/galleries-edit/galleries-edit.module').then( m => m.GalleriesEditPageModule)
  },
  {
    path: 'admin/galleries-make/:id',
    loadChildren: () => import('./admin/galleries-make/galleries-make.module').then( m => m.GalleriesMakePageModule)
  },
  {
    path: 'admin/galleries-make-edit/:id/:galleryId',
    loadChildren: () => import('./admin/galleries-make-edit/galleries-make-edit.module').then( m => m.GalleriesMakeEditPageModule)
  },
  {
    path: 'admin/login',
    loadChildren: () => import('./admin/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: ':pageUrl',
    loadChildren: () => import('./pages/page/page.module').then( m => m.PagePageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
