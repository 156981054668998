import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, OnInit } from '@angular/core';
import { ContentService } from './../../services/content.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  pageArray = [];
  location = window.location.hostname;
  plop = 'plop';

  constructor(private contentService: ContentService, private fireStore: AngularFirestore) { }

  ngOnInit() {
    this.onListPages();
  }

  onListPages() {
    const list = this.contentService.loadLiveList();
    list.valueChanges({idField: 'id'}).subscribe((ss) => {
      this.pageArray = ss;
      // console.log('pageArray:');
      // console.log(this.pageArray);
    });
  }
}
