/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormsModule, FormGroup, ValidatorFn, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';




@Component({
  selector: 'app-boom-covers',
  templateUrl: './boom-covers.component.html',
  styleUrls: ['./boom-covers.component.scss'],
})
export class BoomCoversComponent implements OnInit {

  shapedBoomCover = 95.00;
  blanketBoomCover = 75.00;


  adminEmail = 'james@jabbdesign.com';
  subject = 'Boom Covers quote request from UltimateSails webapp';
  sendMail = '/php/sendEmail.php';

  message: string;
  endpoint: string;

  http: HttpClient;

  boomCoversForm: FormGroup;

  num1: number;
  num2: number;
  solution: number;
  userSolution: number;

  httpResponse: object;

  variable1 = this.getRandomInt(9);
  variable2 = this.getRandomInt(9);
  constructor(http: HttpClient, private fb: FormBuilder, public toastController: ToastController) {
    this.http = http;
   }

  ngOnInit() {
    this.num1 = this.getRandomArbitrary(2, 10);
    this.num2 = this.getRandomArbitrary(2, 10);
    this.solution = +this.num1 + +this.num2;
    console.log('solution: ' + this.solution);
    //Start php via the built in server: $ php -S localhost:8000
    this.endpoint = this.sendMail;

    // formbuilder.group is a function of formbuilder that sets up the [formGroup]="contactUs", plus it'll perform any validators
    // I should really learn more about validators. They look quite important
    this.boomCoversForm = this.fb.group({
      boomMeasurement: [],
      shapedBoomCover: [],
      blanketBoomCover: [],
      makeModel: [],
      name: [],
      companyName: [],
      email: ['', Validators.required],
      contactNumber: [''],
      userSolution: ['', [Validators.required, Validators.min(this.solution), Validators.max(this.solution)]],
    });
  }

  submitForm() {
    // upon submitting the form - (ngSubmit)="submitForm()" - it takes the value of the entire form and puts it into an array (or a json, i can't remember!)
    const postVars = this.boomCoversForm.value;


    // adds to the array with a couple of extra values to pass along to the php file
    // postVars.recipientEmail = this.email;
    postVars.adminEmail = this.adminEmail;
    postVars.subject = this.subject;
    // console.log(postVars);


    // the `.subscribe` is what makes this work as it's an observable, it's like an async or an await, which means it doesn't happen instantly
    // it posts the `postVars` to the php file and outputs `val`. val should be null if it's successful
    this.http.post(this.endpoint, postVars)
      .subscribe((val) => {
        // console.log("POST call successful value returned in body", val);
        if (val == null) {
          // this.httpResponse = true;
          // reset the form, nice and easy like!
          this.boomCoversForm.reset();
          // show the toast thankyou message
          this.presentToast();
        }
      },
      // what to do if val != null
        response => {
          console.log('POST call in error', response);
        },
        () => {
          console.log('The POST observable is now completed.');
        });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Thank you, the form has been completed and sent successfully.',
      duration: 2500,
      color: 'primary',
      keyboardClose: true,
      position: 'middle',
      translucent: true
    });
    toast.present();
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  getRandomArbitrary(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  calculateBoom(){

  }
}
