import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {

  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: IonSlides;

  sliderTwo: any;

  //Configuration for each Slider

  slideOptsTwo = {
    slidesPerView: this.checkScreen(),
    loop: true,
    centeredSlides: false,
    spaceBetween: 20,
    autoplay: true,
    speed: 1600,
    initialSlide: 1,
  };


  constructor(
  ) {

    this.sliderTwo =
    {
      isBeginningSlide: true,
      isEndSlide: false,

    };
  }

  checkScreen(){
    if ( window.innerWidth>1200 ) {
      return 3;
    } else if ( window.innerWidth>900 ) {
      return 2;
    } else {
      return 1;
    }
  }

  //Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  slideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disable navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

}

