import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  public showMenu: boolean;
  constructor(
    public breakpointObserver: BreakpointObserver,

    ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 1200px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }
      });
  }

}
